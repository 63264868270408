import { useContext, useEffect, useState } from "react";
import FormNewCompletion from "./FormNewCompletion";
import ListNewCompletions from "./ListNewCompletions";
import { CompletionsContext, LoadingContext, TypesContext } from "../../App";
import { FormTitleContext } from "../../layout/Vertical/Global";
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";
import { useNavigate } from "react-router";
import { getFilteredData } from "../../utils/functions/filteredForms";
import { useSearchParams } from "react-router-dom";

function NewCompletion() {
    const [allcompletions,] = useContext(CompletionsContext);
    const [, setFormTitle] = useContext(FormTitleContext);
    const [loading, setLoading] = useContext(LoadingContext)
    const navigate = useNavigate()
    const variables = useContext(VariablesContext)
    // init state
    const [typesParameters,] = useContext(TypesContext)
    const [completions, setCompletions] = useState([]);
    const [type, setType] = useState(null);
    let [searchParams] = useSearchParams();

    const msalInstance = useContext(MsalInstanceContext);

    useEffect(() => {
        setFormTitle(null);
    }, [variables, msalInstance])

    useEffect(() => {
        setType(typesParameters.find(t => t.selected === true));
        if (!typesParameters) {
            setLoading(false)
        }
    }, [typesParameters])


    useEffect(() => {
        if (allcompletions && allcompletions.length) {
            if (type) {
                var Ncompletions = allcompletions.filter(c => c.typeId === type.id && (!c.dealId && !c.finished))
                if (Ncompletions && Ncompletions.length > 0) {
                    setCompletions(Ncompletions);
                    setLoading(false)
                }
                else {
                    navigate("/")
                }
            }
        }
    }, [allcompletions, msalInstance, type])

    const [filters, setFilters] = useState([]);
    useEffect(() => {
        if (completions && completions.length) {
            var filtersContruction
            filtersContruction = completions[0]?.parameters?.filter(pa => pa.filter).map(p => { return { id: p.name, name: p.label, description : p.description } });
            filtersContruction = filtersContruction.map(p => {
                var valueprefiltered = false;
                if(searchParams.get(p.id)){
                    valueprefiltered = searchParams.get(p.id).split(",")
                }
                p.options = completions.map(c => c.parameters?.find(pa => pa.name === p.id && pa.filter)).filter((c, index, self) => self.findIndex(sc => sc.value === c.value) === index).filter(c => c !== "" && c !== null && c !== undefined && c.value !== "" && c.value !== null && c.value !== undefined).map(c => {
                    var am = completions.filter(fD => fD.parameters?.find(paL => paL.name === p.id)?.value === c?.value).length
                    return { value: c.value, label: c.valueLabel ? c.valueLabel : c.value, actif: valueprefiltered? valueprefiltered.find(v => v === c.value) : false, hidden: false, amount: am }
                })
                p.options.sort((a, b) => a.label.localeCompare(b.label))
                return p
            }).filter(p => p?.options?.length > 1)
            if (filtersContruction && filtersContruction.length > 0) {
                setFilters(filtersContruction);
            }
        }
    }, [completions]);

    function onDataChange(data, filtersLoc) {
        var fullFilteredData = getFilteredData(filtersLoc, data)
        var filteredData = data;
        var filteredOptions = []
        filtersLoc.forEach(filterLoc => {
            var options = filterLoc.options.map(opt => {
                var vl = filteredData.filter(fD => fD.parameters?.find(pa => pa.name === filterLoc.id)?.value === opt.value)
                var am = fullFilteredData.filter(fD => fD.parameters?.find(pa => pa.name === filterLoc.id)?.value === opt.value).length
                return { ...opt, hidden: vl.length ? false : true, amount: am }
            })
            filteredOptions.push({
                id: filterLoc.id,
                name: filterLoc.name,
                options: options
            })
            filteredData = getFilteredData(filteredOptions, filteredData)
        })
        setFilters(filteredOptions)
    }

    const [view, setView] = useState("form");

    return <div className=" h-screen">{loading ? null : (view === "form" ? <FormNewCompletion completions={completions} filters={filters} onDataChange={onDataChange} setView={() => { setView("list") }} />
        : (view === "list" ? <ListNewCompletions type={type} completions={completions} setFilters={setFilters} filters={filters} setView={setView} /> : null))}</div>
}

export default NewCompletion;