import { useContext, useEffect, useState } from 'react'
import { Badge } from '../../components/catalyst-component/badge';
import { withTranslation } from 'react-i18next';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';
import { CompletionsContext, LoadingContext } from '../../App';
import { MsalInstanceContext, VariablesContext } from '../../auth/AppProvider';
import { initCompletion } from '../../utils/requests/completions';
import LoadingSpinner from '../../components/Animation/LoadingSpinner';

function NewFormCard({ t, completion, type }) {
    const [countdown, setCountdown] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    });
    let navigate = useNavigate();
    const [completions, setCompletions] = useContext(CompletionsContext);
    const msalInstance = useContext(MsalInstanceContext);
    const variables = useContext(VariablesContext);
    const [loading, setLoading] = useContext(LoadingContext)

    useEffect(() => {
        if (completion.activationDateTime && completion.activationDateTime.end) {
            const time = getTimeRemaining(completion.activationDateTime.end);
            setCountdown({
                days: time.days,
                hours: time.hours,
                minutes: time.minutes,
                seconds: time.seconds,
            });
        }
    }, [completion])


    function getTimeRemaining(date) {
        const time = Date.parse(date) - new Date().getTime(),
            days = Math.floor(time / 1000 / 60 / 60 / 24),
            hours = Math.floor((time / 1000 / 60 / 60) % 24),
            minutes = Math.floor((time / 1000 / 60) % 60),
            seconds = Math.floor((time / 1000) % 60);

        return {
            time,
            days,
            hours,
            minutes,
            seconds,
        };
    }

    function postulate() {
        if (!loading) {
            setLoading(true)
            initCompletion(msalInstance, variables, completion.id).then(res => {
                if (res.ok) {
                    return res.json()
                }
            }).then(newCandidature => {
                if (newCandidature) {
                    var completionsLoc = Object.assign([], completions);
                    var index = completionsLoc.findIndex(c => c.id === completion.id)
                    var completionL = newCandidature
                    completionsLoc[index] = completionL
                    setCompletions(completionsLoc)
                    setTimeout(() => {
                        navigate("/" + type.name + "/" + completion.id)
                    }, 1);
                    setTimeout(function () {
                        if (window.location.pathname !== ("/" + type.name + "/" + completion.id)) {
                            window.location.assign("/" + type.name + "/" + completion.id)
                        } else {
                            setLoading(false)
                        }
                    }, 5000);
                }
            }).catch(err => {
                setLoading(false)
            })
        }
    }

    return (
        <div className="overflow-hidden container-box-b card-btn-slide">
            <div className="" >
                {completion?.activationDateTime?.end && countdown.days < 7 ? <div className='text-xs text-bold text-red-500 flex items-center '>
                    <BellAlertIcon className="h-5 w-5 inline-block mr-1 tilt-shaking" />
                    {countdown.days > 0 ?
                        <span className='font-bold'>Plus que {countdown.days} jour{countdown.days > 1 ? "s" : ""} pour remplir le formulaire</span> :
                        countdown.hours > 0 ?
                            <span className='font-bold'>Plus que {countdown.hours}h pour remplir le formulaire</span> :
                            <span className='font-bold'>Plus que {countdown.minutes} minutes pour remplir le formulaire</span>
                    }
                </div> : null}
                <h2 className="font-bold text-gray-900 text-2xl">{completion.parameters?.find(pa => pa.name === "name")?.value}</h2>
                <div className="mt-2 flex gap-1 text-xs">{completion.parameters?.filter(pa => pa.name !== "description" && pa.name !== "name" && pa.value !== null && pa.value !== "" && pa.display)?.map((p, k) => <Badge color="zinc" key={k}>
                    <span className='text-xs'> {p.valueLabel ? p.valueLabel : p.value}</span>
                </Badge>)}</div>
                {/* <p className="mt-3 my-1 h-8 overflow-hidden text-xs">{completion.parameters?.find(pa => pa.name === "description")?.value}</p> */}
                <div className="flex w-full justify-end bottom-0 left-0 my-3">
                    <div className="flex items-center btn-slide hover:text-white z-10 " >
                        <ArrowRightIcon className="h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                        <div className="cursor-pointer" style={{ lineHeight: "16px", fontSize: "16px" }} onClick={() => { postulate() }}>
                            {loading ? <LoadingSpinner/> : t("Postuler")}
                        </div></div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(NewFormCard);