function getFilteredData(filters, listCompletions) {
    var cands = listCompletions;
    filters?.forEach(filter => {
        const values = filter?.options?.filter(opt => opt.actif)
        if (values && values.length) {
            cands = cands.filter(c => values.find(v => v.value === c.parameters?.find(pa => pa.name === filter.id && pa.filter)?.value));
        }
    });
    return cands
}

export { getFilteredData };