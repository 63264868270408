import { useEffect, useState } from "react";
import { Badge } from "../../components/catalyst-component/badge";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { withTranslation } from "react-i18next";
import { BellAlertIcon } from "@heroicons/react/24/outline";


function MyForm({ t, completion, hspipelines, isMobile }) {
    const navigate = useNavigate()
    const [cantBeUpdated, setCantBeUpdated] = useState(false);
    const [stage, setStage] = useState(null);

    useEffect(() => {
        var cantBeUpdated = false;
        var stage = (hspipelines.find(pip => pip.id === completion.pipeline.id)?.stages)?.find(st => st.id === completion.hs_pipeline_stage);
        if (completion.hs_pipeline_stage === completion.hs_pipeline_final) {
            cantBeUpdated = true;
        } else {
            var finalStage = hspipelines.find(pip => pip.id === completion.pipeline.id)?.stages?.find(st => st.id === completion.pipeline.end);
            if (stage) {
                cantBeUpdated = stage.displayOrder >= finalStage.displayOrder;
            } else {
                cantBeUpdated = true;
            }
        }
        setCantBeUpdated(cantBeUpdated);
        setStage(stage);
    }, [completion, hspipelines]);

    const [countdown, setCountdown] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    });




    useEffect(() => {
        if (completion.activationDateTime && completion.activationDateTime.end) {
            const time = getTimeRemaining(completion.activationDateTime.end);
            setCountdown({
                days: time.days,
                hours: time.hours,
                minutes: time.minutes,
                seconds: time.seconds,
            });
        }
    }, [completion])


    function getTimeRemaining(date) {
        const time = Date.parse(date) - new Date().getTime(),
            days = Math.floor(time / 1000 / 60 / 60 / 24),
            hours = Math.floor((time / 1000 / 60 / 60) % 24),
            minutes = Math.floor((time / 1000 / 60) % 60),
            seconds = Math.floor((time / 1000) % 60);

        return {
            time,
            days,
            hours,
            minutes,
            seconds,
        };
    }

    return isMobile ? (
        <div className="overflow-hidden container-box-b card-btn-slide cursor-pointer" onClick={() => !cantBeUpdated ? navigate("/" + completion.id) : null}>
            <div className="" >
                {completion?.activationDateTime?.end && countdown.days < 7 ? <div className='text-xs text-bold text-red-500 flex items-center '>
                    <BellAlertIcon className="h-5 w-5 inline-block mr-1 tilt-shaking" />
                    {countdown.days > 0 ?
                        <span className='font-bold'>Plus que {countdown.days} jour{countdown.days > 1 ? "s" : ""} pour remplir le formulaire</span> :
                        countdown.hours > 0 ?
                            <span className='font-bold'>Plus que {countdown.hours}h pour remplir le formulaire</span> :
                            <span className='font-bold'>Plus que {countdown.minutes} minutes pour remplir le formulaire</span>
                    }
                </div> : null}
                <h2 className="font-bold text-gray-900 text-2xl">{completion.parameters?.find(pa => pa.name === "name")?.value}</h2>
                <div className="mt-2 flex gap-1 text-xs">{completion.parameters?.filter(pa => pa.name !== "description" && pa.name !== "name" && pa.value !== null && pa.value !== "" && pa.display)?.map((p, k) => <Badge color="zinc" key={k}>
                    <span className='text-xs'> {p.valueLabel ? p.valueLabel : p.value}</span>
                </Badge>)}</div>
                <div className="mt-2 flex gap-1 text-xs"><Badge color={cantBeUpdated ? "amber" : "emerald"}>
                    <span className='text-xs'>{stage ? stage.label : (completion.hs_pipeline_stage ? completion.hs_pipeline_stage : null)}</span>
                </Badge></div>
                <p className="mt-3 my-1 h-8 overflow-hidden text-xs">{completion.parameters?.find(pa => pa.name === "description")?.value}</p>
                {!cantBeUpdated && <div className="flex w-full justify-end bottom-0 left-0 my-3">
                    <div className="flex items-center btn-slide hover:text-white z-10 " >
                        <ArrowRightIcon className="h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                        <div className="cursor-pointer " style={{ lineHeight: "16px", fontSize: "16px" }}>
                            {t("Continuer")}
                        </div></div>
                </div>}
            </div>
        </div>
    ) : (<div className={cantBeUpdated ? "form-list" : "form-list cursor-pointer"} onClick={() => !cantBeUpdated ? navigate("/" + completion.id) : null}>
        <span className="form-list-title flex items-center justify-start gap-0 mb-1">
            <span className={cantBeUpdated ? "form-list-name text-gray-500" : " form-list-name-active form-list-name"}>
                <div className="max-w-[28vw] overflow-hidden truncate">{completion.parameters ? completion.parameters?.find(p => p.name === "name")?.value : "-"}</div>
            </span>
            {cantBeUpdated && <span className="form-list-status m-1 ml-4" style={{ backgroundColor: cantBeUpdated ? "orange" : "green", display: "inline-block" }}>{stage ? stage.label : (completion.hs_pipeline_stage ? completion.hs_pipeline_stage : null)}</span>}
            {completion?.activationDateTime?.end && countdown.days < 7 && !cantBeUpdated ? <div className='text-xs text-bold text-red-500 flex items-center ml-2'>
                <BellAlertIcon className="h-5 w-5 inline-block mr-1 tilt-shaking" />
                {countdown.days > 0 ?
                    <span className='font-bold'>Plus que {countdown.days} jour{countdown.days > 1 ? "s" : ""} pour remplir le formulaire</span> :
                    countdown.hours > 0 ?
                        <span className='font-bold'>Plus que {countdown.hours}h pour remplir le formulaire</span> :
                        <span className='font-bold'>Plus que {countdown.minutes} minutes pour remplir le formulaire</span>
                }
            </div> : null}
        </span>
    </div>);
}

export default withTranslation()(MyForm);