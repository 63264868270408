import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { CompletionsContext, LoadingContext, TypesContext } from "../../App"
import { FormTitleContext } from "../../layout/Vertical/Global";
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";
import { SetTrakingHubspot } from "../../utils/functions/trackingHubspot";

import MyForms from "./MyForms";
import PlateformeComingSoon from "../PlateformeComingSoon";
import { useNavigate } from "react-router";



function SectionHomePage({ t }) {
    const navigate = useNavigate()
    const [allcompletions,] = useContext(CompletionsContext);
    const [, setFormTitle] = useContext(FormTitleContext);
    const [loading, setLoading] = useContext(LoadingContext)
    const variables = useContext(VariablesContext)
    // init state
    const [typesParameters,] = useContext(TypesContext)
    const [myCompletions, setMyCompletion] = useState([]);
    const [newCompletion, setNewCompletion] = useState([]);
    const [type, setType] = useState(null);


    const msalInstance = useContext(MsalInstanceContext);


    useEffect(() => {
        setFormTitle(null);
        SetTrakingHubspot(msalInstance, variables.HSTenantID, variables.ClientName, "Home");
    }, [variables, msalInstance])

    useEffect(() => {
        setType(typesParameters.find(t => t.selected === true));
        if (!typesParameters) {
            navigate("/comingsoon")
        }
    }, [typesParameters])


    useEffect(() => {
        if (allcompletions && allcompletions.length) {
            if (type) {
                var Ocompletions = allcompletions.filter(c => c.typeId === type.id && (c.dealId || c.finished))
                setMyCompletion(Ocompletions);
                var Ncompletions = allcompletions.filter(c => c.typeId === type.id && (!c.dealId && !c.finished))
                setNewCompletion(Ncompletions);
                if (Ocompletions && Ocompletions.length > 0) {
                    navigate("")
                    if (Ncompletions && Ncompletions.length > 0) {
                        setLoading(false);
                    }
                } else {
                    navigate("/newform")
                }
            }

        }
    }, [allcompletions, msalInstance, type])

    return allcompletions && allcompletions.length > 0 ? <div className="h-full">
        {!loading ?
            (myCompletions && myCompletions.length > 0 ?
                <MyForms type={type} myCompletions={myCompletions} canCreateANewCompletion={newCompletion && newCompletion.length > 0} changeView={() => { navigate("/newform") }} />
                : <PlateformeComingSoon />)
            : null}
    </div> : <PlateformeComingSoon />
}

export default withTranslation()(SectionHomePage);