import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "../catalyst-component/button";
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";
import * as FormsComponent from "../Inputs/forms/index";
import { PencilIcon } from "@heroicons/react/24/outline";
import { verifyAddress } from "../../utils/requests/completions";

function AddressComponent({ t, question, form, formData, onDataChange }) {
    const variables = useContext(VariablesContext)
    const msalInstance = useContext(MsalInstanceContext);
    const [, setErrorShow] = useState(false);
    const [lockInfo, setLockInfo] = useState(false);
    const [errors, setErrors] = useState(null);
    const [formattedAddress, setFormattedAddress] = useState(null);

    const [address, setAddress] = useState({
        Address1: "",
        Address2: "",
        Zipcode: "",
        City: "",
        Country: ""
    })

    useEffect(() => {
        var locAdd = address;
        question.options.forEach(opt => {
            if (handleShowQuestion(opt.label)) {
                var currQuestion = getQuestion(opt.value);
                if (formData[currQuestion.name] !== "") {
                    locAdd[opt.label] = formData[currQuestion.name];
                }
            }
        })
        setAddress(locAdd);
    }, [formData])

    function handleAddress() {
        setErrors(null);
        if (question.options.find(opt => handleShowQuestion(opt.label) && getQuestion(opt.value)?.required && address[opt.label] === "")) {
            setErrors("Veuillez remplir tous les champs obligatoires")
        } else {
            setLockInfo(true);
            setFormattedAddress(false);
            setErrorShow(false);
            var query = address.Address1 + " " + address.Address2 + " " + address.Zipcode + " " + address.City;
            verifyAddress(msalInstance, variables, query).then(data => {
                var dataFormatted = {
                    Address1: data.numeroVoie + " " + data.libelleVoie,
                    Address2: "",
                    Zipcode: data.codePostal,
                    City: data.commune,
                    Country: address?.Country
                }
                setFormattedAddress(dataFormatted);
                question.options.forEach(opt => {
                    if (handleShowQuestion(opt.label)) {
                        if (dataFormatted[opt.value] !== "") {
                            var currQuestion = getQuestion(opt.value);
                            formData[currQuestion.name] = dataFormatted[opt.label];
                            onDataChange(formData, currQuestion);
                        }
                    }
                })

            }).catch(err => {
                setErrors(err);
                setLockInfo(false);
            })

        }
    }

    function getQuestion(id) {
        return form?.find(q => q.id === id);
    }

    function getOption(field) {
        return question?.options?.find(opt => opt.label === field);
    }

    function handleShowQuestion(field) {
        const optQuestion = getOption(field);
        return optQuestion?.hidden === false && getQuestion(optQuestion?.value) !== null && getQuestion(optQuestion?.value) !== undefined;
    }



    return question ? <div>
        <div className="my-6 border-t border-b border-gray-900 py-6">
            {/* Formulaire de paiement */}
            <div >
                <form>
                    <div className="mb-6">
                        <div className="px-4 sm:px-0 flex justify-between">
                            <div><h2 className="text-base/7 font-semibold text-gray-900">{question.label}</h2>
                            </div>
                            {lockInfo && <div>
                                <PencilIcon className="h-6 w-6 text-gray-500 cursor-pointer" onClick={() => setLockInfo(false)} />
                            </div>}
                        </div>
                        {!lockInfo ?
                            <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-3 mt-4">
                                {errors ? <div className="col-span-full text-red-500">{JSON.stringify(errors)}</div> : null}
                                {handleShowQuestion("Address1") ? <div className="col-span-full">
                                    <label htmlFor="Address1" className="block text-xs font-normal text-gray-900">
                                        {getQuestion(getOption("Address1")?.value)?.label}{getQuestion(getOption("Address1")?.value)?.required ? "*" : ""}
                                    </label>
                                    <div>
                                        <FormsComponent.Input id="Address1" label="Adresse" value={address.Address1} onChange={(e) => setAddress({ ...address, Address1: e })} />
                                    </div>
                                </div> : null}
                                {handleShowQuestion("Address2") ? <div className="col-span-full">
                                    <label htmlFor="Address2" className="block text-xs font-normal text-gray-900">
                                        {getQuestion(getOption("Address2")?.value)?.label}{getQuestion(getOption("Address2")?.value)?.required ? "*" : ""}
                                    </label>
                                    <div>
                                        <FormsComponent.Input id="adress2" label="Adresse2" value={address.Address2} onChange={(e) => setAddress({ ...address, Address2: e })} />
                                    </div>
                                </div> : null}

                                {handleShowQuestion("City") ? <div className="sm:col-span-1 sm:col-start-1">
                                    <label htmlFor="City" className="block text-xs font-normal text-gray-900">
                                        {getQuestion(getOption("City")?.value)?.label}{getQuestion(getOption("City")?.value)?.required ? "*" : ""}
                                    </label>
                                    <div>
                                        <FormsComponent.Input id="City" label="City" value={address.City} onChange={(e) => setAddress({ ...address, City: e })} />
                                    </div>
                                </div> : null}
                                {handleShowQuestion("Zipcode") ? <div className="sm:col-span-1">
                                    <label htmlFor="postal-code" className="block text-xs font-normal text-gray-900">
                                        {getQuestion(getOption("Zipcode")?.value)?.label}{getQuestion(getOption("Zipcode")?.value)?.required ? "*" : ""}
                                    </label>
                                    <div>
                                        <FormsComponent.Input id="Zipcode" label="Code postal" value={address.Zipcode} onChange={(e) => setAddress({ ...address, Zipcode: e })} />
                                    </div>
                                </div> : null}
                                {handleShowQuestion("Country") ? <div className="sm:col-span-1">
                                    <label htmlFor="Country" className="block text-xs font-normal text-gray-900">
                                        {getQuestion(getOption("Country")?.value)?.label}{getQuestion(getOption("Country")?.value)?.required ? "*" : ""}
                                    </label>
                                    <div className="grid grid-cols-1">
                                        <select id="Country" label="Pays" className="border block w-full sm:text-xs border-gray-300 rounded-md h-8 px-3 text-xs"
                                            onChange={(e) => {
                                                setAddress({ ...address, Country: e.target.value })
                                            }}
                                            value={address.Country}>
                                            <option value=""></option>
                                            {getQuestion(getOption("Country")?.value)?.options.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
                                        </select>
                                    </div>
                                </div> : null}
                            </div>
                            : <div>
                                <div>
                                    {formattedAddress.Address1}{" "}{formattedAddress.Address2}{" "}{formattedAddress.Zipcode}{" "}{formattedAddress.City}{" "}{getQuestion(getOption("Country")?.value)?.options.find(opt => opt.value === formattedAddress.Country)?.label}
                                </div>
                            </div>}
                    </div>
                    {!lockInfo && <div className="flex items-center justify-center">
                        <Button type="button" className="cursor-pointer"
                            onClick={handleAddress}>{t("Vérifier l'adresse")}</Button>
                    </div>}
                </form>
                <div>

                </div>
            </div>

        </div>
    </div > : null
}

export default withTranslation()(AddressComponent);