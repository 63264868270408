export default function handleCondition(formData, question, form) {
    if (form.find(q => q.id === question.id)) {
        var questioninForm = form.find(q => q.id === question.id);
        if (questioninForm.sub) {
            var res = false;
            var questionParent = form.find(q => q.id === questioninForm.sub);
            if (questionParent) {
                var conditions = questionParent.conditionalList.filter(c => c.subs.find(s => s === question.id));
                for (var c in conditions) {
                    var resCond = isConditionTrue(conditions[c], formData, questionParent.conditionalList, form, questionParent.name)
                    res = res || resCond
                }
                return res;
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
}

function isConditionTrue(condition, formData, conditionList, form, parent) {
    try {
        var currentres = true;
        if (!condition) {
            return false;
        }
        if (condition?.block) {
            var parentS = form.find(f => f.id === condition.block);
            if (parentS.fieldType === "custom input") {
                currentres = currentres && isConditionTrue(parentS.condition, formData, conditionList, form, parent)
            }
            parent = parentS.name;
        }
        var formDataParent = formData && formData[parent] ? formData[parent] : form?.find(f => f.name === parent)?.value;
        var cond = condition.cond;

        if (condition.select) {
            switch (condition.select) {
                case "contains any of":
                    currentres = cond.find(p => p === formDataParent) ? true : false;
                    break;
                case "contains none of":
                    currentres = formDataParent && cond.find(p => p === formDataParent) ? false : true;
                    break;
                case "is not empty":
                    currentres = formDataParent || formDataParent === 0 ? true : false;
                    break;
                case "is equal to":
                    currentres = cond[0].toLowerCase() === formDataParent.toLowerCase() ? true : false;
                    break;
                case "is not equal to":
                    currentres = cond[0] === formDataParent ? false : true;
                    break;
                case "contains all of":
                    currentres = cond.find(p => !formDataParent.find(c => c === p)) ? false : true;
                    break;
                case "doesn't contain all of":
                    currentres = formDataParent.find(p => cond.find(c => c === p)) ? false : true;
                    break;
                case "is less than":
                    currentres = Number(formDataParent) < Number(cond[0]) ? true : false;
                    break;
                case "is less than or equal to":
                    currentres = formDataParent <= cond[0] ? true : false;
                    break;
                case "is greater than":
                    currentres = formDataParent > cond[0] ? true : false;
                    break;
                case "is greater than or equal to":
                    currentres = formDataParent >= cond[0] ? true : false;
                    break;
                case "is between":
                    currentres = formDataParent >= cond[0] && formDataParent <= cond[1] ? true : false;
                    break;
                case "is not between":
                    currentres = formDataParent < cond[0] || formDataParent > cond[1] ? true : false;
                    break;
                case "else":
                    var locList = conditionList.filter(c => c.select !== "else")
                    currentres = true;
                    for (var x in locList) {
                        if (isConditionTrue(locList[x], formData, locList, form, parent)) {
                            currentres = false;
                            break;
                        }
                    }
                    break;
                default:
                    currentres = false;
                    break;
            }
        } else if (condition.multi) {
            var values = formDataParent.split(";");
            switch (condition.multi) {
                case "is equal to":
                    currentres = cond === values ? true : false;
                    break;
                case "is not equal to":
                    currentres = cond === values ? false : true;
                    break;
                case "contains any of":
                    currentres = values.find(p => cond.find(c => c === p)) ? true : false;
                    break;
                case "contains none of":
                    currentres = values.find(p => cond.find(c => c === p)) ? false : true;
                    break;
                case "contains all of":
                    currentres = cond.find(p => !values.find(c => c === p)) ? false : true;
                    break;
                case "doesn't contain all of":
                    currentres = values.find(p => cond.find(c => c === p)) ? false : true;
                    break;
                case "is not empty":
                    currentres = values ? true : false;
                    break;
                default:
                    currentres = false;
                    break;
            }
        } else if (condition.date) {
            var value = formDataParent ? new Date(formDataParent) : null;
            var date1 = cond && cond.length > 0 ? new Date(cond[0]) : null;
            var date2 = cond && cond.length > 1 ? new Date(cond[1]) : null;
            switch (condition.date) {
                case "is equal to":
                    currentres = date1.getTime() === value.getTime() ? true : false;
                    break;
                case "is less than":
                    currentres = date1.getTime() > value.getTime() ? true : false;
                    break;
                case "is greater than":
                    currentres = date1.getTime() < value.getTime() ? true : false;
                    break;
                case "is between":
                    currentres = date1.getTime() <= value.getTime() && date2.getTime() >= value.getTime() ? true : false;
                    break;
                case "is not between":
                    currentres = date1.getTime() > value.getTime() || date2.getTime() < value.getTime() ? true : false;
                    break;
                case "was more than":
                    currentres = date1.getTime() < value.getTime() ? true : false;
                    break;
                case "was less than":
                    currentres = date1.getTime() > value.getTime() ? true : false;
                    break;
                case "is not empty":
                    currentres = value ? true : false;
                    break;
                default:
                    currentres = false;
                    break;
            }
        } else if (condition.booleancheckbox) {
            switch (condition.booleancheckbox) {
                case "is equal to":
                    currentres = cond.find(p => p === formDataParent) ? true : false;
                    break;
                case "is not empty":
                    currentres = typeof (formDataParent) === "number" || typeof (formDataParent) === "boolean" || formDataParent ? true : false;
                    break;
                default:
                    currentres = false;
                    break;
            }
        } else if (condition.number) {
            switch (condition.number) {
                case "is equal to":
                    currentres = cond[0] === formDataParent ? true : false;
                    break;
                case "is not equal to":
                    currentres = cond[0] === formDataParent ? false : true;
                    break;
                case "is less than":
                    currentres = formDataParent < cond[0] ? true : false;
                    break;
                case "is less than or equal to":
                    currentres = formDataParent <= cond[0] ? true : false;
                    break;
                case "is greater than":
                    currentres = formDataParent > cond[0] ? true : false;
                    break;
                case "is greater than or equal to":
                    currentres = formDataParent >= cond[0] ? true : false;
                    break;
                case "is between":
                    currentres = formDataParent >= cond[0] && formDataParent <= cond[1] ? true : false;
                    break;
                case "is not between":
                    currentres = formDataParent < cond[0] || formDataParent > cond[1] ? true : false;
                    break;
                case "is not empty":
                    currentres = formDataParent ? true : false;
                    break;
                default:
                    currentres = false;
                    break;
            }
        } else if (condition.file) {
            switch (condition.file) {
                case "is not empty":
                    currentres = formDataParent ? true : false;
                    break;
                default:
                    currentres = false;
                    break;
            }
        } else if (condition.misc) {
            switch (condition.misc) {
                case "is equal to":
                    currentres = cond[0] === formDataParent ? true : false;
                    break;
                case "is not equal to":
                    currentres = cond[0] === formDataParent ? false : true;
                    break;
                case "contains":
                    currentres = formDataParent.includes(cond[0]) ? true : false;
                    break;
                case "doesn't contain":
                    currentres = formDataParent.includes(cond[0]) ? false : true;
                    break;
                case "start with":
                    currentres = formDataParent.startsWith(cond[0]) ? true : false;
                    break;
                case "ends with":
                    currentres = formDataParent.endsWith(cond[0]) ? true : false;
                    break;
                case "is not empty":
                    currentres = formDataParent ? true : false;
                    break;
                default:
                    currentres = false;
                    break;
            }
        }
        if (condition?.and !== null && condition?.and?.block !== null && currentres) {
            return (currentres && isConditionTrue(condition.and, formData, conditionList, form, parent))
        } else {
            return currentres
        }
    } catch (err) {
        return false;
    }

}

// function isValueVerified(type, question, value) {
//     if (question) {
//         if (type !== "array" && typeof (question) !== "object") {
//             return question === value;
//         } else {
//             return question.includes(value);
//         }
//     } else {
//         return false;
//     }
// }
