import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import "../../assets/css/formes.css"
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { getFilteredData } from '../../utils/functions/filteredForms';

function HandleNewCompletionForm({ t, completions, filters, onDataChange, setView }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 250);

        return () => clearTimeout(timer);

    }, []);

    return (

        <div className={isVisible ? "my-forms-visible layout h-full overflow-x-hidden overflow-y-auto" : "hidden"}>
            <section className="grid xl:grid-cols-2 xl:gap-6">
                <div className="3xl:mt-[calc(100%_-_60rem)] xl:mr-12 ">
                    <div className="flex-auto">
                        <h1 className="page-title">Choisissez le <span className='text-underline'>programme</span> qui vous convient</h1>
                    </div>
                    <div className='relative hidden xl:block'>
                        <div className="degrade-rect" />
                        <div className='triangle absolute top-0' />
                    </div>
                </div>
                <div>
                    <section className=" xl:ml-48">
                        <div className=' w-full mx-auto xl:mt-4'>
                            <div className=" xl:px-4 xl:pb-4 xl:pt-5 relative">
                                <div className='xl:mb-16'>
                                    <div className="mt-6 xl:ml-4 text-left">
                                        <div>
                                            <p className='page-description mb-2'>Sélectionnez les critères souhaités et découvrez les formations que nous proposons</p>
                                            {filters && filters.filter(f => !['name','description','formType'].find(c => c === f.id)).map((section, sectionIdx) => (
                                                <fieldset className="my-4" key={sectionIdx}>
                                                    <legend className="text-sm font-semibold leading-6 color-primary-perso">{section.description ? section.description : section.name}</legend>
                                                    <div className="mt-2 space-y-2 ">
                                                        {section.options.filter(opt => {
                                                            return !opt.hidden
                                                        })
                                                            .map((option, optionIdx) => (
                                                                <div key={option.value} className="relative flex gap-x-3 sm:col-span-1">
                                                                    <div className="flex h-6 items-center">
                                                                        <input
                                                                            onChange={() => {
                                                                                var filtersLocal = filters;
                                                                                var filterLocal = filters[sectionIdx];
                                                                                var optionId = filterLocal.options.indexOf(option)
                                                                                var optionLocal = filterLocal.options[optionId];
                                                                                optionLocal.actif = !optionLocal.actif;
                                                                                filterLocal.options[optionId] = optionLocal;
                                                                                filtersLocal[sectionIdx] = filterLocal;
                                                                                onDataChange(completions, filtersLocal)
                                                                            }}
                                                                            checked={option.actif}
                                                                            id={`filter-${section.id}-${optionIdx}`}
                                                                            name={`${section.id}[]`}
                                                                            type="checkbox"
                                                                            className="h-4 w-4 border-gray-300"
                                                                        />
                                                                    </div>
                                                                    <div className="text-sm leading-6">
                                                                        <label htmlFor={`filter-${section.id}-${optionIdx}`} className="text-gray-600">{option.label}</label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </fieldset>
                                            ))}
                                        </div>
                                        <div className="xl:flex w-full justify-start mb-5 bottom-0 left-0 mt-12 hidden">
                                            <div className="flex mt-5 items-center btn-slide hover:text-white " onClick={() => {
                                                setView()
                                            }}>
                                                <ArrowRightIcon className="h-12 w-12 cursor-pointer rounded-full p-4 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                                                <div className="cursor-pointer " style={{ lineHeight: "18px", fontSize: "18px" }}>
                                                    {getFilteredData(filters, completions).length > 1 ? t("Afficher les Résultats", { amount: getFilteredData(filters, completions).length }) : (getFilteredData(filters, completions).length === 1 ?t("Afficher le résultat") : t("Aucun résultat"))}
                                                </div></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex w-full xl:hidden my-5">
                                <div className="flex items-center justify-center  text-white  bg-primary-perso w-full  rounded-full cursor-pointer z-10 py-1 " onClick={() => {
                                    setView()
                                }}>
                                    {getFilteredData(filters, completions).length > 1 ? t("Afficher les Résultats", { amount: getFilteredData(filters, completions).length }) : (getFilteredData(filters, completions).length === 1 ?t("Afficher le résultat") : t("Aucun résultat"))}
                                </div>
                            </div>
                        </div>
                    </section></div>
            </section>

        </div>
    )
}

export default withTranslation()(HandleNewCompletionForm);