import { withTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../Hubspot.css"
import { TypesContext, CompletionsContext, ThemeContext, LoadingContext } from "../App"
import { MsalInstanceContext, VariablesContext } from '../auth/AppProvider';

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom/dist";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import { initCompletion } from "../utils/requests/completions";

function ListNewCompletions({ t }) {
    const [completion, setCompletion] = useState({});
    let navigate = useNavigate();
    const [types,] = useContext(TypesContext);
    const [isVisible, setIsVisible] = useState(false);

    let { id, checkoutid, returnpaiement } = useParams();

    const [completions, setCompletions] = useContext(CompletionsContext);
    const msalInstance = useContext(MsalInstanceContext);
    const theme = useContext(ThemeContext);
    const variables = useContext(VariablesContext);
    const [, setLoading] = useContext(LoadingContext)

    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 250);

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {
        var completionCurr = completions && completions.find(c => c.id === id);
        if (completionCurr.dealId) {
            if (completionCurr && completionCurr.step && completionCurr.step[0]) {
                if (checkoutid && checkoutid !== "cancel") {
                    if (completionCurr.step.find(st => st.questions.find(q => q.label === "Paybox"))) {
                        setLoading(false)
                        setCompletion(completionCurr);
                    }
                }
                else {
                    setLoading(false)
                    setCompletion(completionCurr);
                }
            } else {
                navigate("/")
            }
        } else {
            initCompletion(msalInstance, variables, id).then(res => {
                if (res.ok) {
                    return res.json()
                }
            }).then(newCandidature => {
                if (newCandidature) {
                    var completionsLoc = Object.assign([], completions);
                    var index = completionsLoc.findIndex(c => c.id === id)
                    completionsLoc[index] = newCandidature
                    setCompletions(completionsLoc)

                    completionCurr = completionsLoc && completionsLoc.find(c => c.id === id);
                    if (completionCurr && completionCurr.step && completionCurr.step[0]) {
                        if (checkoutid && checkoutid !== "cancel") {
                            if (completionCurr.step.find(st => st.questions.find(q => q.label === "Paybox"))) {
                                setLoading(false)
                                setCompletion(completionCurr);
                            }
                        }
                        else {
                            setLoading(false)
                            setCompletion(completionCurr);
                        }
                    } else {
                        navigate("/")
                    }
                }
            })
        }

    }, [id, completions, theme, navigate, t, checkoutid]);

    useEffect(() => {
        if (returnpaiement && returnpaiement === "paid") {
            var completionCurr = completions && completions.find(c => c.id === id);
            var typeLoc = types?.find(t => t.id === completionCurr?.typeId)?.name
            navigate("/" + typeLoc + "/" + id + "/paid")
        }
    }, [returnpaiement])

    return (
        <div className={isVisible ? "my-forms-visible layout w-screen overflow-x-hidden" : "hidden"}>
            <section className="2xl:grid 2xl:grid-cols-2">
                <div className="hidden 2xl:block mt-6">
                    <div className="flex-auto">
                        <h1 className="page-title ">{completion?.parameters?.find(p => p.name === "name")?.value}</h1>
                    </div>
                </div>
                <div className="hidden 2xl:block relative">
                    <div className="absolute -z-1" style={{ right: "40%" }}>
                        <div className="bg-img bg-img-1" />
                        <div className="bg-img bg-img-2" />
                        <div className="bg-img bg-img-3" />
                        <div className="bg-img bg-img-4" />
                    </div>
                </div>
                <div className="2xl:hidden   z-1  ">
                    <div className="flex-auto py-2 ">
                        <h1 className="page-title  pr-12">{completion?.parameters?.find(p => p.name === "name")?.value}</h1>
                    </div>
                </div>
            </section>
            <div className="xl:grid xl:max-w-7xl xl:grid-cols-5 gap-x-10 xl:pb-6 " >
                {/* Product details */}
                <div className='flex flex-col xl:col-span-2 justify-between h-full bg-white  hidden xl:block mt-2' style={{ zIndex: '10', position: 'relative' }}>

                    <div className="flow-root">
                        <ul className="-mb-8">
                            {completion.parameters?.filter(pa => pa.name !== "description" && pa.name !== "name" && pa.value !== null && pa.value !== "" && pa.display)?.map((param, paramIdx) => (
                                <li key={paramIdx}>
                                    <div className="pt-1.5 w-fit">
                                        <div>
                                            <p className="text-sm text-gray-500 text-left">
                                                {param.label}{' '}:{''}
                                                <span className="font-medium text-gray-900 ml-2">
                                                    {param.valueLabel ? param.valueLabel : param.value}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Product image */}
                <div className=" xl:col-span-3 xl:mt-2">
                    <div>
                        <section aria-labelledby="information-heading " className="my-4 ">

                        </section>
                    </div>
                </div>

            </div>
            <div className="lg:grid lg:grid-cols-3 gap-5 xl:mt-4 h-full w-full relative pr-4 mb-6">
                <div className="container-box-b pl-8">
                    <div className="flex justify-between">
                        <h1 className="w-full text-2xl font-bold mt-2 mb-6">{types?.find(t => t.id === completion?.typeId)?.name}</h1>
                        {completion?.activationDateTime?.end ? <div className="bg-primary-perso text-white rounded-2xl p-3 ">Fin de la complétion le : <b>{(new Date(completion?.activationDateTime?.end)).toLocaleDateString()}</b></div> : null}
                    </div>
                    <div className="flow-root overflow-hidden">
                        <ul className=" w-fit">
                            {completion?.step?.map((step, stepIdx) => (
                                <li key={step.id}>
                                    <div className="relative pb-6">
                                        {stepIdx !== completion?.step.length - 1 ? (
                                            <span aria-hidden="true" className="absolute left-3 top-0 -ml-px h-full w-0.5 bg-gray-800" />
                                        ) : null}
                                        <div className="relative flex space-x-3">
                                            <div>
                                                <span
                                                    className='flex h-6 w-6 items-center justify-center rounded-full ring-4 ring-white bg-gray-900'

                                                >
                                                    {/* <step.icon aria-hidden="true" className="h-5 w-5 text-white" /> */}
                                                    <AcademicCapIcon className="h-3 w-3 text-white" />
                                                </span>
                                            </div>
                                            <div className="pt-0.5 text-left">
                                                <div>
                                                    <p className="text-sm text-gray-900">
                                                        {step.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="block xl:hidden w-full my-6">
                        <div className="flex items-center rounded-full bg-primary-perso w-full text-center px-4 py-2">
                            <div className="cursor-pointer text-white w-full text-center" onClick={() => navigate("/" + types?.find(t => t.id === completion?.typeId)?.name + "/" + completion.id)} style={{ lineHeight: "18px", fontSize: "18px" }}>
                                {completion.dealId ? t("Démarrer") : t("Démarrer")}
                            </div>
                        </div>
                    </div>
                    <div className="hidden xl:flex w-full justify-end bottom-0 left-0 mb-4">
                        <div className="flex items-center btn-slide hover:text-white z-10 " onClick={() => navigate("/" + types?.find(t => t.id === completion?.typeId)?.name + "/" + completion.id)}>
                            <ArrowRightIcon className="h-8 w-8 rounded-full p-2 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                            <div className="cursor-pointer " style={{ lineHeight: "16px", fontSize: "16px" }}>
                                {completion.dealId ? t("Démarrer") : t("Démarrer")}
                            </div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(ListNewCompletions);